import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { ETShapeIcon } from 'components/icons/Shapes'
import { LogoSpreadIcon } from 'components/icons'
import { Deco, Section } from 'components/layout'
import { BackgroundWithGrid } from '../layout'
import { LinkArrowIcon } from '../icons'

const GalleryData = [
  'https://www.facebook.com/tumba.solutions/photos/a.570432366478663/570432423145324/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.700468406808391/700469740141591/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.700468406808391/700469206808311/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.700468406808391/700470120141553/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.700468406808391/700470653474833/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/883448251843738/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.560894540765779/969515986570297/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/952675578254338/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/907733122748584/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/914207778767785/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/937091906479372/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.560894540765779/853526474835916/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.829048760617021/829048887283675/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/952675728254323/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.1102588326596395/1102591736596054/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/1197583693763524/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.1059998740855354/1059999294188632/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.1059998740855354/1059998777522017/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/1078293852359176/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.1102588326596395/1102591009929460/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.1102588326596395/1102591126596115/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.1128214264033801/1128214577367103/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.361920947329807/1197566493765244/?type=3&theater',
  'https://www.facebook.com/tumba.solutions/photos/a.303119069876662/1310985449090014/?type=3&theater',
]

const galleryQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 160, maxHeight: 160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export const GalleryComponent = props => {
  const { imageCount, ...passThroughProps } = props
  const data = useStaticQuery(galleryQuery)
  const nodes = data.allFile.nodes.map(node => ({
    index: parseInt(node.name) - 1,
    link: GalleryData[parseInt(node.name) - 1],
    ...node,
  }))
  const imageSource = nodes
    .sort((lhs, rhs) => lhs.index >= rhs.index)
    .reverse()
    .slice(0, imageCount)

  const images = imageSource.map(node => (
    <a key={node.index} href={node.link} target="_blank" rel="noreferrer">
      <Img fluid={node.childImageSharp.fluid} alt="Tumba spirit image" {...passThroughProps} />
    </a>
  ))
  return <>{images}</>
}

export const PositionIcon = props => (
  <svg viewBox="0 0 81.725 84.013" {...props}>
    <g transform="matrix(1, 0, 0, 1, 0, 0)">
      <path
        d="M820.2,112.867c-18.645,2.4-36.2,13.429-36.2,31.1s13.637,32.089,27.795,34.493,37.086-12.476,35.884-27.622S838.849,110.463,820.2,112.867ZM816,167.974c-10.642,0-19.266-5.117-19.266-11.432,0-4.412,9.393-8.236,15.061-10.142a11.433,11.433,0,1,1,8.409,0c5.669,1.906,15.061,5.73,15.061,10.142C835.265,162.857,826.641,167.974,816,167.974Z"
        transform="translate(-775 -103.71)"
      />
    </g>
  </svg>
)

export const StepIcon = props => {
  return (
    <div className="relative mb-6 h-16 w-16">
      <ETShapeIcon className="text-primary-3 fill-current" {...props} />
      <div className="absolute inset-0 -mt-2 grid justify-center items-center text-5xl text-primary-2">{props.children}</div>
    </div>
  )
}

export class ApplyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: props.messageHint || 'position',
      destination: props.destination || 'join',
      message: props.message || '',
      messageHint: props.messageHint || 'You want to chat about...',
      subject: props.subject || '',
      subjectHint: props.subjectHint || '',
    }
  }

  handleSubjectChange(event) {
    this.setState({ subject: event.target.value })
  }

  handleMessageChange(event) {
    this.setState({ message: event.target.value })
  }

  handleSubmit(event) {
    // obscure join email for crawlers
    // TODO(Emo): refactor to use similar approach to https://github.com/coston/react-obfuscate
    window.location.href = ['mai', 'lto:', this.state.destination, '@tum', 'ba.solutions?subject=', this.state.subject, '&body=', this.state.message].join('')
    event.preventDefault()
  }

  render() {
    return (
      <form className="mt-6 flex flex-col items-start">
        <input
          onChange={this.handleSubjectChange.bind(this)}
          className="my-6 w-full lg:w-2/3 py-2 text-lg bg-transparent appearance-none border-b border-primary-3 focus:outline-none"
          name={this.state.type}
          type="text"
          value={this.state.subject}
          placeholder={this.state.subjectHint}
          aria-label={this.state.type}
        />
        <input
          onChange={this.handleMessageChange.bind(this)}
          className="my-6 w-full lg:w-2/3 py-2 text-lg bg-transparent appearance-none border-b border-primary-3 placeholder-secondary-1 focus:outline-none"
          name="message"
          type="text"
          placeholder={this.state.messageHint}
          aria-label="message"
          value={this.state.message}
        />
        <button onClick={this.handleSubmit.bind(this)} className="mt-8 px-4 pt-1 pb-2 text-3xl lg:text-4xl bg-secondary-1 text-primary-2">
          Submit
        </button>
      </form>
    )
  }
}

export const ApplyAndWhatsNextSections = ({ position, active = true }) => (
  <>
    <BackgroundWithGrid image="careers-apply" bgBottom gridRow={3} />
    <Deco className="z-0 bg-black bg-opacity-50" gridRow={3} />
    <Section className="z-20 my-40 h-72" gridRow={3}>
      {active ? (
        <div>
          <h2 className="text-3xl lg:text-4xl text-primary-1">Apply for this position</h2>
          <ApplyForm subject={position} />
        </div>
      ) : null}
    </Section>
    <Section className="mt-16" gridRow={4}>
      <h2 className="text-4xl lg:text-5xl text-shadow">So what's next?</h2>
      <h3 className="mt-4 text-3xl lg:text-4xl text-shadow">Recruitment steps</h3>
      <div className="mt-16 grid gap-16 grid-cols-1 lg:grid-cols-3 text-lg text-primary-2">
        <div>
          <div className="float-left sm:float-none mr-4">
            <StepIcon>1</StepIcon>
          </div>
          <p>Review</p>
          <p className="mt-2 font-normal tracking-normal">We will read carefully your submitted application. In case you have published open source code, projects or PRs, we'll go through them as well.</p>
        </div>
        <div>
          <div className="float-left sm:float-none mr-4">
            <StepIcon rotate={-75}>2</StepIcon>
          </div>
          <p>Interview</p>
          <p className="mt-2 font-normal tracking-normal">We conduct two interviews. First one aligns expectations and overviews each other's expertise. Second one is an in-depth technological chat and development opportunities discussion.</p>
        </div>
        <div>
          <div className="float-left sm:float-none mr-4">
            <StepIcon rotate={90}>3</StepIcon>
          </div>
          <p>Offer</p>
          <p className="mt-2 font-normal tracking-normal">We make an offer to the candidate we think is the best fit for the role. In case you think different, we are open for another conversation.</p>
        </div>
      </div>
      <LogoSpreadIcon className="m-auto mt-16 h-20" />
    </Section>
  </>
)

export const ApplyAndWhatsNextSectionsDevCamp = ({ position, active = true }) => (
  <>
    <BackgroundWithGrid image="careers-apply" bgBottom gridRow={3} />
    <Deco className="z-0 bg-black bg-opacity-50" gridRow={3} />
    <Section className="z-20 my-40 h-20" gridRow={3}>
      {active ? (
        <Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfXj7NNsp3By2lEFW2xAUCRX3x_xvqO1Jyn2QWuMU18dB3Y7A/viewform" className="mt-4 flex items-baseline">
          <h2 className="uppercase text-3xl lg:text-4xl text-primary-1">Join DevCamp</h2>
          <LinkArrowIcon className="ml-2 w-8 text-primary-1 stroke-current" />
        </Link>
      ) : null}
    </Section>
    <Section className="mt-16" gridRow={4}>
      <h2 className="text-4xl lg:text-5xl text-shadow">So what's next?</h2>
      <h3 className="mt-4 text-3xl lg:text-4xl text-shadow">Recruitment steps</h3>
      <div className="mt-16 grid gap-16 grid-cols-1 lg:grid-cols-3 text-lg text-primary-2">
        <div>
          <div className="float-left sm:float-none mr-4">
            <StepIcon>1</StepIcon>
          </div>
          <p>Review</p>
          <p className="mt-2 font-normal tracking-normal">We will read your submitted application carefully. In case you have published open-source code, we'll go through it as well.</p>
        </div>
        <div>
          <div className="float-left sm:float-none mr-4">
            <StepIcon rotate={-75}>2</StepIcon>
          </div>
          <p>Selection</p>
          <p className="mt-2 font-normal tracking-normal">We conduct an entry test and an interview that aligns expectations, expertise and development opportunities.</p>
        </div>
        <div>
          <div className="float-left sm:float-none mr-4">
            <StepIcon rotate={90}>3</StepIcon>
          </div>
          <p>Future opportunities</p>
          <p className="mt-2 font-normal tracking-normal">If you manage all assignments and show commitment and progress, a paid internship can be offered at the end of the program.</p>
        </div>
      </div>
      <LogoSpreadIcon className="m-auto mt-16 h-20" />
    </Section>
  </>
)

import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO
      title="Java Backend Developer with Spring Boot"
      pageUrl="/careers/java/"
      type="article"
      cardImage="/img/tumbacardjobs1.png"
      description="You find Java exciting! Are you curious about the platform, amazed by it's capabilities and wondering what else could be built with it, Let's Talk!"
    />
    <LdJsonJobPosting
      title="Java Backend Developer with Spring Boot"
      description="You find Java exciting! Are you curious about the platform, amazed by it's capabilities and wondering what else could be built with it, Let's Talk!"
      publishDate="2022-01-31T08:17:42.000Z"
      minSalary="4500.00"
      maxSalary="6500.00"
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">
          Java Backend Developer <br /> with Spring Boot
        </h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            <strong className="text-primary-1 font-normal">You find Java exciting</strong>! Are you curious about the platform, amazed by it's capabilities and wondering what else could be built with it?
          </p>
          <p className="sm:mt-6 font-normal">We do too!</p>
          <p className="sm:mt-6 font-normal">
            To all like-minded Java enthusiasts, <strong className="text-primary-1 font-normal">let's talk</strong> and explore together how deep the rabbit hole goes and does it lead to Wonderland!
          </p>
          <p className="sm:mt-6">
            What we'd like to do <strong className="text-primary-1">together</strong>:
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Automate challenging workflows to increase time and money spent in a more meaningful way.</li>
            <li>Integrate systems that were never thought of being able to talk to each other.</li>
            <li>Optimise flows using machine learning… and Java.</li>
          </ul>
          <p className="sm:mt-6 font-normal">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">Identifies gaps in requirements and proactively seeks clearance and refinement.</li>
                <li className="mt-4">Breaks down features into easy-to-estimate tasks and take them through all its stages in a predictive manner with attention to details.</li>
                <li className="mt-4">Write high quality, maintainable code.</li>
                <li className="mt-4">Be curious about new technologies, methodologies and procedures.</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> of Java, Java Platform and Spring Boot.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Proficient</strong> in OOD and OOP, natively applies SOLID principles, design patterns where needed and YAGNI.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">3+ years</strong> of backend development using Java, at least an year using Spring or Spring Boot.
                </li>
                <li className="mt-4">Experience with messaging solutions, storage systems and integration patterns.</li>
                <li className="mt-4">Knows how to design and consume robust APIs.</li>
                <li className="mt-4">Self-organised, proactive and good communicator.</li>
                <li className="mt-4">Able to look through the customer lens.</li>
                <li className="mt-4">
                  Experience in creating or maintaining SPA is <strong className="text-primary-1 font-normal">a plus</strong>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="Java Backend Developer with Spring Boot" active={false} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
